import { Fragment, useMemo, useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Map, Select } from '../../shared/components';
import { mapsKey } from '../../config/mapsKey';
import navLogo from '../../shared/assets/images/nav-logo.svg';
import slide1 from '../../shared/assets/images/home/banner1.jpg';
import slide2 from '../../shared/assets/images/home/banner2.jpg';
import slide3 from '../../shared/assets/images/home/banner3.jpg';
import di824 from '../../shared/assets/images/home/di824.png';
import digia from '../../shared/assets/images/home/digia.png';
import di824p2 from '../../shared/assets/images/home/di824p2.png';
import brochureInstitucional from '../../shared/assets/images/pdf/brochuredigex2019.pdf';
import footerDGX from '../../shared/assets/images/dgx.png';
import { ReactComponent as CarouselImage } from '../../shared/assets/images/carousel-logo.svg';
import useApi from '../../shared/hooks/api';
import { mostrarModal } from '../../shared/hooks/modal';

import { default as ModalProductoDIGEX824 } from './ProductoDIGEX824.modal'
import { default as ModalProductoDIGEX824Premium } from './ProductoDIGEX824Premium.modal'
import { default as ModalProductoDIGEXIA } from './ProductoDIGEXIA.modal'

const mapURL = `https://maps.googleapis.com/maps/api/js?key=${mapsKey}`;

const Default = () => {
	const [{ data, error }, obtenerReceptorias] = useApi.post('/obtenerReceptorias', {}, { lazy: true });
	const rows = useMemo(() => (error ? [] : data || []), [data, error]);

	const [mapa, asignarMapa] = useState({
		mostrarProvincias: false,
		provincia: null,
		receptoria: null,
	});

	const provincias = useMemo(() => {
		return rows.reduce((provincias, provincia) => {
			if (!provincias) {
				provincias = [{ label: provincia.descripcionCompletaJurisdiccion, value: provincia.idJurisdiccion }];
			} else if (provincias.filter((x) => x.value === provincia.idJurisdiccion).length === 0) {
				provincias.push({ label: provincia.descripcionCompletaJurisdiccion, value: provincia.idJurisdiccion });
			}

			return provincias;
		}, []);
	}, [rows]);

	const receptorias = useMemo(() => {
		if (!mapa.provincia) {
			return [];
		}

		const receptoriasProvinciaFiltrado = rows.filter((x) => x.idJurisdiccion === mapa.provincia);

		return receptoriasProvinciaFiltrado.map((r) => {
			if (r.direccion === null || r.horarioDesde === null || r.horarioHasta === null) {
				return {
					label: `${r.zona} - Esta recepción es de retiro a domicilio, comuníquese con nosotros a info@digex.com.ar para coordinar el proceso.`,
					value: r.idReceptoria,
				};
			}

			return {
				label: `${r.zona} - ${r.direccion} - Horario de tope Recepción de ${r.horarioDesde.substr(0, 5)} a ${r.horarioHasta.substr(0, 5)} hs`,
				value: r.idReceptoria,
			};
		});
	}, [rows, mapa.provincia]);

	useEffect(() => {
		obtenerReceptorias();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="Default">
			<div className="container-default">
				{/* BANNER */}
				<nav id="banner-default">
					<hr style={{ height: '2px' }} />
					<div className="my-3 px-4">
						<img src={navLogo} alt="nav-logo" style={{ width: '185px', height: '65px', marginRight: '30px' }} />

						<a href="#inicio" className="text-decoration-none cc">
							INICIO
						</a>
						{/* <a href="#servicio" className="text-decoration-none cc">
							SERVICIOS
						</a>
						<a href="#contacto" className="text-decoration-none cc">
							CONTACTO
						</a>
						<a href="#receptorias" className="text-decoration-none cc">
							RECEPTORÍAS
						</a> */}
						<div className="dropdown">
							<button title="ingreso">INGRESO</button>
							<div>
								<a href="/Account/Login">INGRESO DIGEX824</a>
								<a href="/Account/Login">INGRESO DIGEX-IA</a>
								<a href="https://www.mopdigex.com.ar/">MOP</a>
							</div>
						</div>
					</div>
				</nav>

				{/* CAROUSEL */}
				<div>
					<CarouselImage className="logoc" />
					<Carousel controls={false} indicators={false}>
						<Carousel.Item>
							<img className="d-block w-100" src={slide1} alt="First slide" />
						</Carousel.Item>
						<Carousel.Item>
							<img className="d-block w-100" src={slide2} alt="Second slide" />
						</Carousel.Item>
						<Carousel.Item>
							<img className="d-block w-100" src={slide3} alt="Third slide" />
						</Carousel.Item>
					</Carousel>
				</div>

				{/* INICIO */}
				<div id="inicio" className="text-center">
					<div style={{ marginTop: '10px' }}>
						<h2>Sitio Web en construcción. </h2>
						{/* <p className="opacity-50">
							<i>Nuestro propósito:</i>
						</p> */}
						{/* <p className="text-center">
							Sitio Web en construcción. 
						</p> */}
						<p>Pronto tendremos novedades.</p>
					</div>
				</div>

				{/* SERVICIOS */}
				{/* <hr />
				<div id="servicio" className="text-center">
					<div>
						<h2>NUESTROS SERVICIOS</h2>
						<p className="opacity-50">
							<i>Para mayor información acerca de nuestros productos, haga click sobre ellos</i>
						</p>

						<div className="row">
							<div className="col servicios" onClick={() => mostrarModal('ProductoDIGEX824')}>
								<img src={di824} alt="DIGEX824" />
								<h5 className="text-image">Prestador de Servicios de Archivo y Digitalización</h5>
							</div>
							<div className="col servicios" onClick={() => mostrarModal('ProductoDIGEXIA')}>
								<img src={digia} alt="DIGEX-IA" />
								<h5 className="text-image">Análisis de datos sensibles</h5>
							</div>
							<div className="col servicios" onClick={() => mostrarModal('ProductoDIGEX824Premium')}>
								<img src={di824p2} alt="DIGEX824 Premium" />
								<h5 className="text-image">Prestador de Servicios de Archivo y Digitalización</h5>
							</div>
						</div>
					</div>
				</div> */}

				{/* BROCHURE INSTITUCIONAL */}
				{/* <div id="brochure">
					<div>
						<p>BROCHURE INSTITUCIONAL</p>
						<hr style={{ width: '60%' }} />
						<p>Si usted desea puede descargar nuestra Brochure Institucional digital en formato PDF.</p>
						<a className="btn btn-primary" href={brochureInstitucional} target="blank">
							Descargar
						</a>
					</div>
				</div> */}

				{/* CONTACTO */}
				{/* <div id="contacto">
					<div>
						<h2 className="text-center">CONTACTO</h2>
						<p className="text-center">
							<i>Todos nuestros medios están a su disposición</i>
						</p>
						<div className="row">
							<div className="col-sm-12 text-center">
								<a className="text-decoration-none" href="mailto:info@digex.com.ar">
									Email: info@digex.com.ar
								</a>
								<br />
								
								<br />
								<a className="text-decoration-none" href="tel:+541143113547">
									Teléfono: [+5411] 4311-3547
								</a>
								<br />
								Paraguay 610, Piso 11 - C.A.B.A., Argentina
								<br />
								Código postal: C1057AAJ
								<br />
							</div>
						</div>
					</div>
				</div> */}

				{/* RECEPTORIAS */}
				{/* <div id="receptorias">
					<div>
						<p className="title">RECEPTORÍAS DIGEX</p>
						<p className="subtitle">
							<i>Receptoría oficina central - Paraguay 610, piso 11</i>
						</p>
						<p className="d-none">
							<i>Seleccione una provincia y luego una receptoría.</i>
						</p>
						<button
							onClick={() => asignarMapa((state) => ({ ...state, mostrarProvincias: !state.mostrarProvincias, provincia: null, receptoria: null }))}
							className="btn btn-primary mt-3 mb-3"
						>
							Ver más receptorías
						</button>

						{mapa.mostrarProvincias && (
							<Fragment>
								<div className="mb-3">
									<label>Seleccionar Provincia</label>
									<Select
										placeholder="Seleccionar..."
										options={provincias}
										isClearable
										onChange={(e) => asignarMapa((state) => ({ ...state, provincia: e ? e.value : null, receptoria: null }))}
									/>
								</div>
								<div>
									<label>Seleccionar receptoría:</label>
									<Select
										value={mapa.receptoria ? receptorias.filter(x => x.value === mapa.receptoria) : null}
										placeholder="Seleccionar..."
										options={receptorias}
										isClearable
										onChange={(e) => asignarMapa((state) => ({ ...state, receptoria: e ? e.value : null }))}
									/>
								</div>
							</Fragment>
						)}
					</div>

					<div id="map">
						<Map
							googleMapURL={mapURL}
							containerElement={<div style={{ height: '400px' }} />}
							mapElement={<div style={{ height: '100%' }} />}
							loadingElement={<p>Cargando...</p>}
							mapa={mapa}
							receptorias={rows}
						/>
					</div>
				</div> */}

				{/* FOOTER */}
				<hr />
				<footer>
					<div id="footer-default">
						<div>
							<img src={footerDGX} alt="Logo Footer" />
						</div>
					</div>
				</footer>
			</div>

			<ModalProductoDIGEX824 />
			<ModalProductoDIGEX824Premium />
			<ModalProductoDIGEXIA />
		</div>
	);
};

export default Default;
